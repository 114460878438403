class Menu {
  static init() {
    document.querySelectorAll('.toggle-icon').forEach((icon) => {
      icon.addEventListener('click', (event) => {
        document.querySelectorAll('.my-navbar').forEach((navbar) => navbar.classList.toggle('show'));
        document.querySelectorAll('.mainmenu-area').forEach((mainMenu) => mainMenu.classList.toggle('show'));
        document.getElementById('nav-icon3').classList.toggle('open');
      });
    });
    // global.$('.toggle-icon').on('click', () => {
    //   global.$('.my-navbar').toggleClass('show');
    //   global.$('.mainmenu-area').toggleClass('show');
    //   global.$('#nav-icon3').toggleClass('open');
    // });
  }
}

export default Menu;
