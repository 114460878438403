import 'bootstrap';

import Lazy from './js/components/atoms/lazy';
import Loader from './js/components/molecules/loader';
import Menu from './js/components/molecules/menu';
import Portfolio from './js/components/pages/portfolio';
import Typed from './js/components/molecules/typed';

if (document.querySelectorAll('.lazy').length > 0) {
  Lazy.init();
}

Menu.init();

if (document.querySelectorAll('.project-gallery-item').length > 0) {
  Portfolio.init();
}

if (document.getElementById('typed')) {
  Typed.init();
}

window.addEventListener('load', () => {
  Loader.init();
});
