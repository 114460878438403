class Lazy {
  static init() {
    const lazyImages = [].slice.call(global.document.querySelectorAll('.lazy'));

    if ('IntersectionObserver' in global.window) {
      const lazyImageObserver = new global.IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;

            Lazy.preloadImage(lazyImage.dataset.src).then((src) => {
              lazyImage.src = src;
              lazyImage.classList.remove('lazy');
              lazyImage.style.height = '';
              Lazy.overlay(lazyImage, entry.target, src);
            }).catch(() => {});
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });

      lazyImages.forEach((lazyImage) => {
        lazyImageObserver.observe(lazyImage);
      });
    } else {
      // Possibly fall back to a more compatible method here
    }
  }

  static preloadImage(url) {
    return new Promise((resolve, reject) => {
      const img = new global.Image();

      img.addEventListener('load', () => resolve(url));
      img.addEventListener('error', () => reject(url));
      img.src = url;
    });
  }
}

export default Lazy;
