import Lazy from "../atoms/lazy";

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
import mixitup from 'mixitup';

class Portfolio {
  static init() {
    const containerEl = document.querySelector('.project-gallery-item');
    const mixer = mixitup(containerEl, { animation: { duration: 300 } });
    const portfolioModal = new bootstrap.Modal('#portfolio-modal');

    document.querySelectorAll('.gallery-item').forEach((item) => {
      item.addEventListener(
        'click',
        (event) => {
          event.preventDefault();
          fetch(event.target.closest('.gallery-item-content').getAttribute('data-href'), { method: 'GET' })
            .then(response => response.text())
            .then(html => {
              document.getElementById('portfolio-modal').querySelector('.modal-body').innerHTML = html;
              portfolioModal.show('show');
              Lazy.init();
            });
        }
      );
    })
  }
}

export default Portfolio;
