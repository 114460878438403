import Typed from 'typed.js';

class TypedInformation {
  static init() {
    const strings = document.getElementById('typed').getAttribute('data-strings');

    new Typed(
      '#typed',
      {
        strings: JSON.parse(strings),
        stringsElement: null,
        typeSpeed: 70,
        startDelay: 150,
        backSpeed: 40,
        backDelay: 350,
        loop: false,
        loopCount: 550,
        showCursor: true,
        cursorChar: '|',
        attr: null,
        contentType: 'html',
      },
    );

    // if ($typed.length > 0) {
    //
    // }
  }
}

export default TypedInformation;
